import { BrowserProvider } from 'ethers'
import { BigNumber } from 'bignumber.js'
import { captureException } from '@sentry/browser'
import { BaseWallet } from './base'
import { CHAINS_NATIVE_ASSET, CLIENTS } from '~/clients'

// const ETHEREUM_DEV_NETWORK: string = 'mainnet' // 'goerli' for testnet, 'mainnet' for mainnet
// const USE_ETH_NETWORK = process.env.NODE_ENV === 'development' && ETHEREUM_DEV_NETWORK === 'goerli'
// const ETH_NETWORK = USE_ETH_NETWORK ? 'goerli' : 'mainnet'
const mayanodeEndpoint = 'https://node.eldorado.market/mayanode'

export const TOKENS_DECIMALS = {
  '0xdac17f958d2ee523a2206206994597c13d831ec7': 6,
  '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48': 6,
  '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0': 18,
}

export class XDefiWallet extends BaseWallet {
  kujiGasAdjustment: number = 5.1
  btcFeeAdjustment: number = 1.1
  ethGasAdjustment: number = 1.15
  xDefiObject: any
  provider: BrowserProvider = new BrowserProvider(window.xfi.ethereum, 'any')
  address: string

  constructor(chain: string, xDefiObject: any, address: string) {
    super(chain)
    this.kujiGasAdjustment = 5.1
    this.btcFeeAdjustment = 1.1
    this.ethGasAdjustment = 1.15
    this.xDefiObject = xDefiObject
    if (chain === 'ETH' || chain === 'ARB') {
      this.provider = new BrowserProvider(window.xfi.ethereum, 'any')
    }
    this.address = address
  }

  async deposit({ amount, memo, asset }) {
    const inbounds = await (await fetch(mayanodeEndpoint + '/mayachain/inbound_addresses')).json()
    switch (this.chain) {
      case 'MAYA':
        return this.depositMayachain({ amount, memo, asset })
      case 'THOR':
        return this.depositThorchain({ inbounds, amount, memo })
      case 'ETH':
        return this.depositEthereum({ inbounds, amount, memo, asset })
      case 'ARB':
        return this.depositArbitrum({ inbounds, amount, memo, asset })
      case 'BTC':
        return this.depositBitcoin({ inbounds, amount, memo })
      case 'KUJI':
        return this.depositKujira({ inbounds, amount, memo, asset })
    }
  }

  async checkTokenAllowanceMaya(asset) {
    const inbounds = await (await fetch(mayanodeEndpoint + '/mayachain/inbound_addresses')).json()
    switch (this.chain) {
      case 'ETH':
        return this.checkEthereumTokenAllowanceMaya({ inbounds, asset })
      case 'ARB':
        return this.checkArbitrumTokenAllowanceMaya({ inbounds, asset })
    }
  }

  async checkTokenApproveFeeMaya(asset) {
    const inbounds = await (await fetch(mayanodeEndpoint + '/mayachain/inbound_addresses')).json()
    switch (this.chain) {
      case 'ETH':
        return this.checkEthereumTokenApproveFeeMaya({ inbounds, asset })
      case 'ARB':
        return this.checkArbitrumTokenApproveFeeMaya({ inbounds, asset })
    }
  }

  async approveOrCheckTokenRango({
    checkOrApprove,
    poolIn,
    poolOut,
    toAddress,
    amountIn,
  }: ApproveOrCheckTokenRangoParams) {
    switch (this.chain) {
      case 'ETH':
        return await this.checkEthereumApproveOrCheckTokenRango({
          checkOrApprove,
          poolIn,
          poolOut,
          toAddress,
          amountIn,
        })
      case 'ARB':
        return await this.checkArbitrumApproveOrCheckTokenRango({
          checkOrApprove,
          poolIn,
          poolOut,
          toAddress,
          amountIn,
        })
    }
  }

  async approveTokenMaya(asset) {
    const inbounds = await (await fetch(mayanodeEndpoint + '/mayachain/inbound_addresses')).json()
    switch (this.chain) {
      case 'ETH':
        return this.approveEthereumTokenMaya({ inbounds, asset })
      case 'ARB':
        return this.approveArbitrumTokenMaya({ inbounds, asset })
    }
  }

  async depositMayachain({ amount, memo, asset }) {
    let assetName = (asset || CHAINS_NATIVE_ASSET[this.chain]).toLowerCase()
    if (assetName.startsWith('maya.')) {
      assetName = assetName.slice(5)
    }
    let decimals = 8
    if (assetName === 'maya') decimals = 4
    if (assetName === 'cacao') decimals = 10
    const amountStr = (parseFloat(amount) * 10 ** decimals).toFixed(0)
    asset = {
      chain: 'MAYA',
      symbol: assetName,
      ticker: assetName,
    }
    const from = this.address
    amount = {
      amount: amountStr,
      decimals,
    }
    const tx = await new Promise((resolve, reject) =>
      window.xfi.mayachain.request(
        {
          method: 'deposit',
          params: [
            {
              asset,
              from,
              amount,
              memo,
            },
          ],
        },
        (error, response) => (error ? reject(error) : resolve(response)),
      ),
    )
    return tx
  }

  async depositRuneOnThorchain({ amount, memo, asset }) {
    // const assetName = (asset || CHAINS_NATIVE_ASSET[this.chain]).toLowerCase()

    const amountStr = new BigNumber(amount).times(1e8).toFixed(0)

    const decimals = 8

    asset = {
      chain: 'THOR',
      symbol: 'RUNE',
      ticker: 'RUNE',
    }
    const from = this.address
    amount = {
      amount: amountStr,
      decimals,
    }
    const tx = await new Promise((resolve, reject) =>
      window.xfi.thorchain.request(
        {
          method: 'deposit',
          params: [
            {
              asset,
              from,
              amount,
              memo,
            },
          ],
        },
        (error, response) => (error ? reject(error) : resolve(response)),
      ),
    )
    return tx
  }

  async depositThorchain({ inbounds, amount, memo }, skipSimulation = false) {
    const inbound = inbounds.find((i) => i.chain === this.chain)
    if (!skipSimulation) {
      const newAmount = await super.calculateRuneFee(amount)
      if (newAmount > parseFloat(amount)) {
        return await this.depositThorchain({ inbounds, amount: newAmount, memo }, true)
      }
    }
    return this.transferThorchain({ to: inbound.address, amount, memo })
  }

  depositKujira({ inbounds, amount, memo, asset }) {
    const inbound = inbounds.find((i) => i.chain === this.chain)
    return this.transferKujira({ to: inbound.address, amount, memo, asset })
  }

  async depositEthereum({ inbounds, amount, memo, asset = '' }) {
    await window.xfi.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x1' }], // '0xa4b1' is the chain ID for Arbitrum One in hexadecimal
    })
    const signer = await this.provider.getSigner()
    return super.depositEthereum(signer, { inbounds, amount, memo, asset })
  }

  async depositArbitrum({ inbounds, amount, memo, asset = '' }) {
    try {
      await window.xfi.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0xa4b1' }], // '0xa4b1' is the chain ID for Arbitrum One in hexadecimal
      })
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask
      if (switchError.code === 4902) {
        try {
          await window.xfi.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0xa4b1', // Arbitrum One chain ID
                chainName: 'Arbitrum One',
                rpcUrls: [
                  'https://arb-mainnet.g.alchemy.com/v2/BU8TokIdf3qW_vUV2DJZsJVTv3YQcOb3',
                ] /* ...additional parameters like block explorer URLs here */,
              },
            ],
          })
        } catch (addError) {
          // handle "add" error
          captureException(`Failed to add Arbitrum network: ${addError}`)
        }
      }
      // handle other "switch" errors
      captureException(`Failed to switch to Arbitrum network: ${switchError}`)
    }
    const signer = await this.provider.getSigner()
    return super.depositArbitrum(signer, { inbounds, amount, memo, asset })
  }

  async depositBitcoin({ inbounds, amount, memo }) {
    const inbound = inbounds.find((i) => i.chain === 'BTC')
    return await this.transferBitcoin({ to: inbound.address, amount, memo })
  }

  async checkEthereumTokenAllowanceMaya({ inbounds, asset = '' }) {
    await window.xfi.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x1' }], // '0xa4b1' is the chain ID for Arbitrum One in hexadecimal
    })
    const signer = await this.provider.getSigner()
    return super.checkEthereumTokenAllowanceMaya(signer, { inbounds, asset })
  }

  async checkArbitrumTokenAllowanceMaya({ inbounds, asset = '' }) {
    try {
      await window.xfi.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0xa4b1' }], // '0xa4b1' is the chain ID for Arbitrum One in hexadecimal
      })
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask
      if (switchError.code === 4902) {
        try {
          await window.xfi.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0xa4b1', // Arbitrum One chain ID
                chainName: 'Arbitrum One',
                rpcUrls: [
                  'https://arb-mainnet.g.alchemy.com/v2/BU8TokIdf3qW_vUV2DJZsJVTv3YQcOb3',
                ] /* ...additional parameters like block explorer URLs here */,
              },
            ],
          })
        } catch (addError) {
          // handle "add" error
          console.error('Failed to add Arbitrum network:', addError)
        }
      }
      // handle other "switch" errors
      console.error('Failed to switch to Arbitrum network:', switchError)
    }
    const signer = await this.provider.getSigner()
    return super.checkArbitrumTokenAllowanceMaya(signer, { inbounds, asset })
  }

  async checkEthereumTokenApproveFeeMaya({ inbounds, asset = '' }) {
    await window.xfi.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x1' }], // '0xa4b1' is the chain ID for Arbitrum One in hexadecimal
    })
    const signer = await this.provider.getSigner()
    return super.checkEthereumTokenApproveFeeMaya(signer, { inbounds, asset })
  }

  async checkArbitrumTokenApproveFeeMaya({ inbounds, asset = '' }) {
    try {
      await window.xfi.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0xa4b1' }], // '0xa4b1' is the chain ID for Arbitrum One in hexadecimal
      })
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask
      if (switchError.code === 4902) {
        try {
          await window.xfi.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0xa4b1', // Arbitrum One chain ID
                chainName: 'Arbitrum One',
                rpcUrls: [
                  'https://arb-mainnet.g.alchemy.com/v2/BU8TokIdf3qW_vUV2DJZsJVTv3YQcOb3',
                ] /* ...additional parameters like block explorer URLs here */,
              },
            ],
          })
        } catch (addError) {
          // handle "add" error
          console.error('Failed to add Arbitrum network:', addError)
        }
      }
      // handle other "switch" errors
      console.error('Failed to switch to Arbitrum network:', switchError)
    }
    const signer = await this.provider.getSigner()
    return super.checkArbitrumTokenApproveFeeMaya(signer, { inbounds, asset })
  }

  async approveEthereumTokenMaya({ inbounds, asset = '' }) {
    await window.xfi.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x1' }], // '0xa4b1' is the chain ID for Arbitrum One in hexadecimal
    })
    const signer = await this.provider.getSigner()
    return super.approveEthereumTokenMaya(signer, { inbounds, asset })
  }

  async approveArbitrumTokenMaya({ inbounds, asset = '' }) {
    try {
      await window.xfi.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0xa4b1' }], // '0xa4b1' is the chain ID for Arbitrum One in hexadecimal
      })
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask
      if (switchError.code === 4902) {
        try {
          await window.xfi.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0xa4b1', // Arbitrum One chain ID
                chainName: 'Arbitrum One',
                rpcUrls: [
                  'https://arb-mainnet.g.alchemy.com/v2/BU8TokIdf3qW_vUV2DJZsJVTv3YQcOb3',
                ] /* ...additional parameters like block explorer URLs here */,
              },
            ],
          })
        } catch (addError) {
          // handle "add" error
          console.error('Failed to add Arbitrum network:', addError)
        }
      }
      // handle other "switch" errors
      console.error('Failed to switch to Arbitrum network:', switchError)
    }
    const signer = await this.provider.getSigner()
    return super.approveArbitrumTokenMaya(signer, { inbounds, asset })
  }

  async checkEthereumApproveOrCheckTokenRango({
    checkOrApprove,
    poolIn,
    poolOut,
    toAddress,
    amountIn,
  }: ApproveOrCheckTokenRangoParams) {
    await window.xfi.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x1' }], // '0xa4b1' is the chain ID for Arbitrum One in hexadecimal
    })
    const signer = await this.provider.getSigner()
    return await super.checkEthereumApproveOrCheckTokenRango({
      checkOrApprove,
      poolIn,
      poolOut,
      toAddress,
      amountIn,
      signer,
      chain: this.chain,
    })
  }

  async checkArbitrumApproveOrCheckTokenRango({
    checkOrApprove,
    poolIn,
    poolOut,
    toAddress,
    amountIn,
  }: ApproveOrCheckTokenRangoParams) {
    try {
      await window.xfi.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0xa4b1' }], // '0xa4b1' is the chain ID for Arbitrum One in hexadecimal
      })
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask
      if (switchError.code === 4902) {
        try {
          await window.xfi.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0xa4b1', // Arbitrum One chain ID
                chainName: 'Arbitrum One',
                rpcUrls: [
                  'https://arb-mainnet.g.alchemy.com/v2/BU8TokIdf3qW_vUV2DJZsJVTv3YQcOb3',
                ] /* ...additional parameters like block explorer URLs here */,
              },
            ],
          })
        } catch (addError) {
          // handle "add" error
          console.error('Failed to add Arbitrum network:', addError)
        }
      }
      // handle other "switch" errors
      console.error('Failed to switch to Arbitrum network:', switchError)
    }
    const signer = await this.provider.getSigner()
    return await super.checkArbitrumApproveOrCheckTokenRango({
      checkOrApprove,
      poolIn,
      poolOut,
      toAddress,
      amountIn,
      signer,
      chain: this.chain,
    })
  }

  transfer({ to, amount, asset, memo }) {
    switch (this.chain) {
      case 'MAYA':
        return this.transferMayachain({ to, amount, asset, memo })
      case 'THOR':
        return this.transferThorchain({ to, amount, memo })
      case 'ETH':
        return this.transferEthereum({ to, amount, asset })
      case 'ARB':
        return this.transferArbitrum({ to, amount, asset })
      case 'BTC':
        return this.transferBitcoin({ to, amount, asset })
      case 'KUJI':
        return this.transferKujira({ to, amount, asset })
    }
  }

  async transferMayachain({ to, amount, asset, memo = '' }) {
    let assetName = (asset || CHAINS_NATIVE_ASSET[this.chain]).toLowerCase()
    if (assetName.startsWith('maya.')) {
      assetName = assetName.slice(5)
    }
    let decimals = 8
    if (assetName === 'maya') decimals = 4
    if (assetName === 'cacao') decimals = 10
    const amountStr = (parseFloat(amount) * 10 ** decimals).toFixed(0)
    asset = {
      chain: 'MAYA',
      symbol: assetName,
      ticker: assetName,
    }
    const denomAmount = {
      amount: amountStr,
      decimals,
    }

    const from = this.address
    const recipient = to
    const tx = await new Promise((resolve, reject) =>
      window.xfi.mayachain.request(
        {
          method: 'transfer',
          params: [
            {
              asset,
              from,
              recipient,
              amount: denomAmount,
              memo,
            },
          ],
        },
        (error, response) => (error ? reject(error) : resolve(response)),
      ),
    )
    return tx
  }

  async transferThorchain({ to, amount, memo = '' }, skipSimulation = false) {
    const asset = {
      chain: 'THOR',
      symbol: 'RUNE',
      ticker: 'RUNE',
    }

    const amountStr = (parseFloat(amount) * 1e8).toFixed(0)
    const denomAmount = {
      amount: amountStr,
      decimals: 8,
    }

    const from = this.address
    const recipient = to

    if (!skipSimulation) {
      const newAmount = await super.calculateRuneFee(amount)
      if (newAmount > parseFloat(amount)) {
        return await this.transferThorchain({ to, amount: newAmount, memo }, true)
      }
    }

    const tx = await new Promise((resolve, reject) =>
      window.xfi.thorchain.request(
        {
          method: 'transfer',
          params: [
            {
              asset,
              from,
              recipient,
              amount: denomAmount,
              memo,
            },
          ],
        },
        (error, response) => (error ? reject(error) : resolve(response)),
      ),
    )
    return tx
  }

  async transferKujira({ to, amount, memo = '', asset }) {
    const signer = await window.xfi.keplr.getOfflineSignerAuto(this.config.chainId)
    return super.transferKujira(signer, { to, amount, memo, asset })
  }

  async transferEthereum({ to, amount, asset }) {
    await window.xfi.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x1' }], // '0xa4b1' is the chain ID for Arbitrum One in hexadecimal
    })
    const signer = await this.provider.getSigner()
    return super.transferEthereum(signer, { to, amount, asset }, false)
  }

  async transferArbitrum({ to, amount, asset }) {
    try {
      await window.xfi.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0xa4b1' }], // '0xa4b1' is the chain ID for Arbitrum One in hexadecimal
      })
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask
      if (switchError.code === 4902) {
        try {
          await window.xfi.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0xa4b1', // Arbitrum One chain ID
                chainName: 'Arbitrum One',
                rpcUrls: [
                  'https://arb-mainnet.g.alchemy.com/v2/BU8TokIdf3qW_vUV2DJZsJVTv3YQcOb3',
                ] /* ...additional parameters like block explorer URLs here */,
              },
            ],
          })
        } catch (addError) {
          // handle "add" error
          captureException(`Failed to add Arbitrum network: ${addError}`)
        }
      }
      // handle other "switch" errors
      captureException(`Failed to switch to Arbitrum network: ${switchError}`)
    }
    const signer = await this.provider.getSigner()
    return super.transferArbitrum(signer, { to, amount, asset }, false)
  }

  async transferBitcoin({ to, amount, memo = '' }, skipSimulation = false) {
    async function fetchUserUtxos(address) {
      const url = `https://mempool.space/api/address/${address}/utxo`
      return (await fetch(url)).json()
    }
    async function fetchFeeRate() {
      const res = await fetch(`https://mempool.space/api/v1/fees/recommended`)
      return (await res.json()).fastestFee
    }
    function calculateFee(vins, vouts, feeRate) {
      const txSize = 10 + vins * 180 + vouts * 34
      return txSize * feeRate
    }
    if (memo && memo.length > 80) {
      throw new Error('memo too long, must not be longer than 80 chars.')
    }
    const feeRate = await fetchFeeRate()

    // check if the fee + amount is bigger than the balance
    const from = this.address
    if (!skipSimulation) {
      const utxos = await fetchUserUtxos(from)
      utxos.sort((a, b) => b.value - a.value)
      amount = parseFloat(amount)
      let value = 0
      let fee = 0
      let inputs = 0
      while (value < amount + fee) {
        const utxo = utxos.pop()
        if (!utxo) throw new Error('Not enough funds in wallet for transaction')
        value += utxo.value
        inputs++
        fee = calculateFee(inputs, memo ? 3 : 2, feeRate)
      }
      const balance = await CLIENTS.BTC.balance(from)
      const amountPlusFee = parseFloat((amount + fee / 1e8).toFixed(8))
      if (amountPlusFee > balance) {
        const confirm = window.confirm(
          `The fee + amount for this transaction is bigger than your balance. ~${(fee / 1e8).toFixed(8)} BTC will be deducted from the amount you are sending. Do you want to continue?`,
        )
        if (!confirm) {
          throw new Error('Transaction cancelled')
        } else {
          const newAmount = (amount - fee / 1e8).toFixed(8)
          return await this.transferBitcoin({ to, amount: newAmount, memo }, true)
        }
      }
    }

    amount = (parseFloat(amount) * 1e8).toFixed(0)
    const denomAmount = {
      amount,
      decimals: 8,
    }
    const recipient = to
    const tx = await new Promise((resolve, reject) =>
      window.xfi.bitcoin.request(
        {
          method: 'transfer',
          params: [
            {
              feeRate,
              from,
              recipient,
              amount: denomAmount,
              memo,
            },
          ],
        },
        (error, response) => (error ? reject(error) : resolve(response)),
      ),
    )

    return tx
  }
}
