import { SigningStargateClient } from '@cosmjs/stargate'
import { Contract, MaxUint256, ethers, hexlify, parseUnits, toUtf8Bytes } from 'ethers'
import { accountParser, aminoTypes, msg, registry } from 'kujira.js'
import { captureException } from '@sentry/browser'
import { CLIENTS } from '~/clients'
import { useRangoRouteStore } from '@/store/rangoRouteStore'

const ETHEREUM_DEV_NETWORK: string = 'mainnet' // 'goerli' for testnet, 'mainnet' for mainnet
const USE_ETH_NETWORK = process.env.NODE_ENV === 'development' && ETHEREUM_DEV_NETWORK === 'goerli'
const ETH_NETWORK = USE_ETH_NETWORK ? 'goerli' : 'mainnet'
const ETHERSCAN_KEY = '2K6ZF6CMS5YJDYYZ18BB2EYRIT7V2JAPJY'
const ETHERSCAN_URL =
  ETH_NETWORK === 'mainnet' ? 'https://api.etherscan.io/api' : 'https://api-goerli.etherscan.io/api'
const ETHERSCAN_GAS_ORACLE_URL = `${ETHERSCAN_URL}?module=gastracker&action=gasoracle&apikey=${ETHERSCAN_KEY}`
const mayanodeEndpoint = 'https://node.eldorado.market/mayanode'

export class BaseWallet {
  address: string = ''
  chain: string
  config: any
  signer: any
  kujiGasAdjustment: number = 1.5
  ethGasAdjustment: number = 1.05
  btcFeeAdjustment: number = 1

  constructor(chain: string) {
    this.chain = chain
    this.config = {
      MAYA: {
        networkId: 931,
        prefix: 'maya',
        chainId: 'mayachain-mainnet-v1',
        endpoint: mayanodeEndpoint,
      },
      THOR: {
        networkId: 931,
        prefix: 'thor',
        chainId: 'thorchain-mainnet-v1',
        endpoint: 'https://thornode.ninerealms.com',
      },
      KUJI: {
        networkId: 118,
        prefix: 'kujira',
        chainId: 'kaiyo-1',
        endpoint: 'https://kujira.rpc.kjnodes.com',
      },
      DASH: {
        networkId: 5,
      },
    }[chain]
  }

  async checkEthereumTokenAllowanceMaya(signer, { inbounds, asset = '' }) {
    const inbound = inbounds.find((i) => i.chain === 'ETH')
    let tokenAddress = (asset.split('-')[1] || '').toLowerCase()
    if (!tokenAddress) {
      tokenAddress = '0x0000000000000000000000000000000000000000'
    }
    const contractToken = new Contract(
      tokenAddress,
      [
        'function decimals() view returns (uint8)',
        'function allowance(address owner, address spender) view returns (uint)',
        'function approve(address spender, uint value)',
      ],
      signer,
    )
    if (asset !== 'ETH.ETH') {
      const allowance = await contractToken.allowance(this.address, inbound.router)
      return allowance
    }
  }

  async checkArbitrumTokenAllowanceMaya(signer, { inbounds, asset = '' }) {
    const inbound = inbounds.find((i) => i.chain === 'ARB')
    let tokenAddress = (asset.split('-')[1] || '').toLowerCase()
    if (!tokenAddress) {
      tokenAddress = '0x0000000000000000000000000000000000000000'
    }
    const contractToken = new Contract(
      tokenAddress,
      [
        'function decimals() view returns (uint8)',
        'function allowance(address owner, address spender) view returns (uint)',
        'function approve(address spender, uint value)',
      ],
      signer,
    )
    if (asset !== 'ARB.ETH') {
      const allowance = await contractToken.allowance(this.address, inbound.router)
      return allowance
    }
  }

  async checkEthereumTokenApproveFeeMaya(signer, { inbounds, asset = '' }) {
    const gasOracle = await (await fetch(ETHERSCAN_GAS_ORACLE_URL)).json()
    // const gasPriceGwei = gasOracle.result.ProposeGasPrice
    const gasPriceEth = gasOracle.result.ProposeGasPrice / 1e9 // gwei to eth

    const inbound = inbounds.find((i) => i.chain === 'ETH')
    let tokenAddress = (asset.split('-')[1] || '').toLowerCase()
    if (!tokenAddress) {
      tokenAddress = '0x0000000000000000000000000000000000000000'
    }
    const contractToken = new Contract(
      tokenAddress,
      [
        'function decimals() view returns (uint8)',
        'function allowance(address owner, address spender) view returns (uint)',
        'function approve(address spender, uint value)',
      ],
      signer,
    )
    if (asset !== 'ETH.ETH') {
      const approveEstimatedGas = await contractToken.approve.estimateGas(
        inbound.router,
        MaxUint256,
      )
      // console.log('check approve approveEstimatedGas==' + approveEstimatedGas)
      // console.log('check approve gasPriceGwei==' + gasPriceGwei)
      // console.log('check approve gasPriceEth==' + gasPriceEth)
      const approveEstimatedFee = gasPriceEth * parseFloat(approveEstimatedGas.toString())
      // console.log('check approve approveEstimatedFee==' + approveEstimatedFee)
      return approveEstimatedFee
    }
  }

  async checkArbitrumTokenApproveFeeMaya(signer, { inbounds, asset = '' }) {
    const url = 'https://arbitrum-mainnet.core.chainstack.com/706562b7f66620d15f1693ebb7c70157' // TODO hide api key behind reverse proxy
    const gasFeeProvider = new ethers.JsonRpcProvider(url)
    const gasPriceEth = parseInt(await gasFeeProvider.send('eth_gasPrice')) / 1e18

    const inbound = inbounds.find((i) => i.chain === 'ARB')
    let tokenAddress = (asset.split('-')[1] || '').toLowerCase()
    if (!tokenAddress) {
      tokenAddress = '0x0000000000000000000000000000000000000000'
    }
    const contractToken = new Contract(
      tokenAddress,
      [
        'function decimals() view returns (uint8)',
        'function allowance(address owner, address spender) view returns (uint)',
        'function approve(address spender, uint value)',
      ],
      signer,
    )
    if (asset !== 'ARB.ETH') {
      const approveEstimatedGas = await contractToken.approve.estimateGas(
        inbound.router,
        MaxUint256,
      )
      // console.log('check approve approveEstimatedGas==' + approveEstimatedGas)
      // console.log('check approve gasPriceGwei==' + gasPriceGwei)
      // console.log('check approve gasPriceEth==' + gasPriceEth)
      const approveEstimatedFee = gasPriceEth * parseFloat(approveEstimatedGas.toString())
      // console.log('check approve approveEstimatedFee==' + approveEstimatedFee)
      return approveEstimatedFee
    }
  }

  async approveEthereumTokenMaya(signer, { inbounds, asset = '' }) {
    // const gasOracle = await (await fetch(ETHERSCAN_GAS_ORACLE_URL)).json()
    // const gasPriceEth = gasOracle.result.ProposeGasPrice / 1e9 // gwei to eth

    const inbound = inbounds.find((i) => i.chain === 'ETH')
    let tokenAddress = (asset.split('-')[1] || '').toLowerCase()
    if (!tokenAddress) {
      tokenAddress = '0x0000000000000000000000000000000000000000'
    }
    const contractToken = new Contract(
      tokenAddress,
      [
        'function decimals() view returns (uint8)',
        'function allowance(address owner, address spender) view returns (uint)',
        'function approve(address spender, uint value)',
      ],
      signer,
    )
    if (asset !== 'ETH.ETH') {
      // const approveEstimatedGas = await contractToken.approve.estimateGas(
      //   inbound.router,
      //   MaxUint256,
      // )
      // console.log('check approve approveEstimatedGas==' + approveEstimatedGas)
      // console.log('check approve gasPriceEth==' + gasPriceEth)
      // const approveEstimatedFee = gasPriceEth * parseFloat(approveEstimatedGas.toString())
      // console.log('check approve approveEstimatedFee==' + approveEstimatedFee)

      // const approveEthBalance = await CLIENTS.ETH.balance(this.address)
      // console.log('check approve approveEthBalance==' + approveEthBalance)

      // commented out to let the wallet handle the error
      // if approveEstimatedFee > balance, throw error because they do not have enough to approve
      // if (approveEstimatedFee > approveEthBalance) {
      //   throw new Error('Not enough Eth to approve the token')
      // }

      await (await contractToken.approve(inbound.router, MaxUint256)).wait()
    }
  }

  async approveArbitrumTokenMaya(signer, { inbounds, asset = '' }) {
    // const gasOracle = await (await fetch(ETHERSCAN_GAS_ORACLE_URL)).json()
    // const gasPriceEth = gasOracle.result.ProposeGasPrice / 1e9 // gwei to eth

    const inbound = inbounds.find((i) => i.chain === 'ARB')
    let tokenAddress = (asset.split('-')[1] || '').toLowerCase()
    if (!tokenAddress) {
      tokenAddress = '0x0000000000000000000000000000000000000000'
    }
    const contractToken = new Contract(
      tokenAddress,
      [
        'function decimals() view returns (uint8)',
        'function allowance(address owner, address spender) view returns (uint)',
        'function approve(address spender, uint value)',
      ],
      signer,
    )
    if (asset !== 'ARB.ETH') {
      // const approveEstimatedGas = await contractToken.approve.estimateGas(
      //   inbound.router,
      //   MaxUint256,
      // )
      // console.log('check approve approveEstimatedGas==' + approveEstimatedGas)
      // console.log('check approve gasPriceEth==' + gasPriceEth)
      // const approveEstimatedFee = gasPriceEth * parseFloat(approveEstimatedGas.toString())
      // console.log('check approve approveEstimatedFee==' + approveEstimatedFee)

      // const approveArbBalance = await CLIENTS.ARB.balance(this.address)
      // console.log('check approve approveArbBalance==' + approveArbBalance)

      // commented out to let the wallet handle the error
      // if approveEstimatedFee > balance, throw error because they do not have enough to approve
      // if (approveEstimatedFee > approveArbBalance) {
      //   throw new Error('Not enough Eth to approve the token')
      // }

      await (await contractToken.approve(inbound.router, MaxUint256)).wait()
    }
  }

  async checkEthereumApproveOrCheckTokenRango({
    checkOrApprove,
    poolIn,
    poolOut,
    toAddress,
    amountIn,
    signer,
    chain,
  }: CheckApproveOrCheckTokenRangoParams) {
    const rangoRouteStore = useRangoRouteStore()
    const { checkTokenApprovalStatusRango } = rangoRouteStore

    return await checkTokenApprovalStatusRango({
      checkOrApprove,
      poolIn,
      poolOut,
      toAddress,
      amountIn,
      signer,
      chain,
      gasOracleURL: ETHERSCAN_GAS_ORACLE_URL,
    })
  }

  async checkArbitrumApproveOrCheckTokenRango({
    checkOrApprove,
    poolIn,
    poolOut,
    toAddress,
    amountIn,
    signer,
    chain,
  }: CheckApproveOrCheckTokenRangoParams) {
    const { checkTokenApprovalStatusRango } = useRangoRouteStore()
    return await checkTokenApprovalStatusRango({
      checkOrApprove,
      poolIn,
      poolOut,
      toAddress,
      amountIn,
      signer,
      chain,
      gasOracleURL: ETHERSCAN_GAS_ORACLE_URL,
    })
  }

  async depositEthereum(signer, { inbounds, amount, memo, asset = '' }, skipSimulation = false) {
    const gasOracle = await (await fetch(ETHERSCAN_GAS_ORACLE_URL)).json()
    const gasPriceEth = gasOracle.result.ProposeGasPrice / 1e9 // gwei to eth

    const inbound = inbounds.find((i) => i.chain === 'ETH')
    let tokenAddress = (asset.split('-')[1] || '').toLowerCase()
    if (!tokenAddress) {
      tokenAddress = '0x0000000000000000000000000000000000000000'
    }
    const contractToken = new Contract(
      tokenAddress,
      [
        'function decimals() view returns (uint8)',
        'function allowance(address owner, address spender) view returns (uint)',
        'function approve(address spender, uint value)',
      ],
      signer,
    )
    const contract = new Contract(
      inbound.router,
      ['function deposit(address,address,uint,string)'],
      signer,
    )
    let decimals = 18
    if (asset !== 'ETH.ETH') decimals = await contractToken.decimals()
    const n = parseUnits(amount, decimals)
    if (asset !== 'ETH.ETH') {
      const allowance = await contractToken.allowance(this.address, inbound.router)
      if (allowance < n) {
        await (await contractToken.approve(inbound.router, MaxUint256)).wait()
      }
    }

    const depositArgs = [
      inbound.address,
      tokenAddress,
      n,
      memo,
      {
        value: asset === 'ETH.ETH' ? n : 0,
      },
    ]
    // if asset is ETH, check if the fee + amount is bigger than the balance
    const ethBalance = await CLIENTS.ETH.balance(this.address)
    if (!skipSimulation && asset === 'ETH.ETH') {
      const estimatedGas = await contract.deposit.estimateGas(...depositArgs)
      const estimatedFee = gasPriceEth * parseFloat(estimatedGas.toString())
      const safeFee = parseFloat((estimatedFee * this.ethGasAdjustment).toFixed(18))
      const amountPlusFee = parseFloat(amount) + safeFee
      // if amount + fee > balance, call function recursively with skipSimulation = true and amount = amount - fee
      if (amountPlusFee > ethBalance) {
        const confirm = window.confirm(
          `The fee + amount for this transaction is bigger than your balance. ~${safeFee} ETH will be deducted from the amount you are sending. Do you want to continue?`,
        )
        if (!confirm) throw new Error('Transaction cancelled')
        return await this.depositEthereum(
          { inbounds, amount: (parseFloat(amount) - safeFee).toFixed(18), memo, asset },
          true,
        )
      } else {
        const tx = await contract.deposit(...depositArgs)
        return tx.hash
      }
    } else {
      // check that balance is enough for fee
      const estimatedGas = await contract.deposit.estimateGas(...depositArgs)
      const estimatedFee = gasPriceEth * parseFloat(estimatedGas.toString())
      const safeFee = estimatedFee * this.ethGasAdjustment
      if (safeFee > ethBalance) {
        throw new Error('Not enough ETH for transaction fee')
      }
      const tx = await contract.deposit(...depositArgs)
      return tx.hash
    }
  }

  async depositArbitrum(signer, { inbounds, amount, memo, asset = '' }, skipSimulation = false) {
    // This was the original ethereum code, left here for reference
    // const gasPriceEth = gasOracle.result.ProposeGasPrice / 1e9 // gwei to eth

    const url = 'https://arbitrum-mainnet.core.chainstack.com/706562b7f66620d15f1693ebb7c70157' // TODO hide api key behind reverse proxy
    const gasFeeProvider = new ethers.JsonRpcProvider(url)
    const gasPriceEth = parseInt(await gasFeeProvider.send('eth_gasPrice')) / 1e18

    const inbound = inbounds.find((i) => i.chain === 'ARB')
    let tokenAddress = (asset.split('-')[1] || '').toLowerCase()
    if (!tokenAddress) {
      tokenAddress = '0x0000000000000000000000000000000000000000'
    }
    const contractToken = new Contract(
      tokenAddress,
      [
        'function decimals() view returns (uint8)',
        'function allowance(address owner, address spender) view returns (uint)',
        'function approve(address spender, uint value)',
      ],
      signer,
    )
    const contract = new Contract(
      inbound.router,
      ['function deposit(address,address,uint,string)'],
      signer,
    )
    let decimals = 18
    if (asset !== 'ARB.ETH') decimals = await contractToken.decimals()
    const n = parseUnits(amount, decimals)
    if (asset !== 'ARB.ETH') {
      const allowance = await contractToken.allowance(this.address, inbound.router)
      if (allowance < n) {
        await (await contractToken.approve(inbound.router, MaxUint256)).wait()
      }
    }

    const depositArgs = [
      inbound.address,
      tokenAddress,
      n,
      memo,
      {
        value: asset === 'ARB.ETH' ? n : 0,
      },
    ]
    // if asset is ETH, check if the fee + amount is bigger than the balance
    const ethBalance = await CLIENTS.ARB.balance(this.address)
    if (!skipSimulation && asset === 'ARB.ETH') {
      await contract.deposit.estimateGas(...depositArgs)
      // const estimatedGas = await contract.deposit.estimateGas(...depositArgs)
      // const estimatedFee = gasPriceEth * parseFloat(estimatedGas.toString())
      // const safeFee = parseFloat((estimatedFee * this.ethGasAdjustment).toFixed(18))
      // const amountPlusFee = parseFloat(amount) + safeFee
      // if amount + fee > balance, call function recursively with skipSimulation = true and amount = amount - fee
      // if (amountPlusFee > ethBalance) {
      // const confirm = window.confirm(
      //   `The fee + amount for this transaction is bigger than your balance. ~${safeFee} ETH will be deducted from the amount you are sending. Do you want to continue?`,
      // )
      // if (!confirm) throw new Error('Transaction cancelled')
      // return await this.depositEthereum(
      //   { inbounds, amount: (parseFloat(amount) - safeFee).toFixed(18), memo, asset },
      //   true,
      // )
      // } else {
      try {
        await signer.provider.getNetwork()
      } catch (e) {
        if (e.message.includes('network changed')) {
          // show network change modal
          alert('check network on your wallet: ' + e.message)
          // or switchEthereumChain ...
        } else {
          captureException(`Error getting chainId: ${e.message}`)
        }
        return
      }
      const tx = await contract.deposit(...depositArgs)
      return tx.hash
      // }
    } else {
      // check that balance is enough for fee

      const estimatedGas = await contract.deposit.estimateGas(...depositArgs)
      const estimatedFee = gasPriceEth * parseFloat(estimatedGas.toString())
      const safeFee = estimatedFee * this.ethGasAdjustment
      if (safeFee > ethBalance) {
        throw new Error(
          `Not enough ETH for transaction fee. Fee: ${safeFee}. Balance: ${ethBalance}`,
        )
      }
      try {
        await signer.provider.getNetwork()
      } catch (e) {
        if (e.message.includes('network changed')) {
          // show network change modal
          alert('check network on your wallet: ' + e.message)
          // or switchEthereumChain ...
        } else {
          captureException(`Error getting chainId: ${e.message}`)
        }
        return
      }
      const tx = await contract.deposit(...depositArgs)
      return tx.hash
    }
  }

  async transferEthereum(signer, { to, amount, asset }) {
    const gasOracle = await (await fetch(ETHERSCAN_GAS_ORACLE_URL)).json()
    const gasPriceEth = gasOracle.result.ProposeGasPrice / 1e9 // gwei to eth
    const ethBalance = await CLIENTS.ETH.balance(this.address)
    const from = this.address

    if (asset === 'ETH.ETH') {
      const value = parseUnits(amount, 18)
      // check if the fee + amount is bigger than the balance
      // if (!skipSimulation && asset === 'ETH.ETH') {
      //   const estimatedGas = await signer.estimateGas({ from, to, value })
      //   const estimatedFee = gasPriceEth * parseFloat(estimatedGas.toString())
      //   // if amount + fee > balance, call function recursively with skipSimulation = true and amount = amount - fee
      //   if (parseFloat(amount) + estimatedFee > ethBalance) {
      //     const confirm = window.confirm(
      //       `The fee + amount for this transaction is bigger than your balance. ~${estimatedFee} ETH will be deducted from the amount you are sending. Do you want to continue?`,
      //     )
      //     if (!confirm) throw new Error('Transaction cancelled')
      //     value = parseUnits((parseFloat(amount) - estimatedFee).toFixed(18), 18)
      //   }
      // }

      const tx = await signer.sendTransaction({ from, to, value })
      return tx.hash
    } else {
      const tokenContract = (asset.split('-')[1] || '').toLowerCase()
      const contract = new Contract(
        tokenContract,
        ['function decimals() view returns (uint8)', 'function transfer(address,uint)'],
        signer,
      )
      const decimals = await contract.decimals()
      const n = parseUnits(amount, decimals)
      // estimate contract call gas
      const estimatedGas = await contract.transfer.estimateGas(to, n)
      const estimatedFee = gasPriceEth * parseFloat(estimatedGas.toString())
      if (estimatedFee > ethBalance) {
        throw new Error(
          `Not enough ETH for transaction fee. Fee: ${estimatedFee}. Balance: ${ethBalance}`,
        )
      }
      const tx = await contract.transfer(to, n)
      return tx.hash
    }
  }

  async transferArbitrum(signer, { to, amount, asset, memo = '' }) {
    const url = 'https://arbitrum-mainnet.core.chainstack.com/706562b7f66620d15f1693ebb7c70157' // TODO hide api key behind reverse proxy
    const gasFeeProvider = new ethers.JsonRpcProvider(url)
    const gasPriceEth = parseInt(await gasFeeProvider.send('eth_gasPrice')) / 1e18
    const ethBalance = await CLIENTS.ARB.balance(this.address)
    const from = this.address

    if (asset === 'ARB.ETH') {
      const value = parseUnits(amount, 18)
      // check if the fee + amount is bigger than the balance
      // if (!skipSimulation && asset === 'ETH.ETH') {
      //   const estimatedGas = await signer.estimateGas({ from, to, value })
      //   const estimatedFee = gasPriceEth * parseFloat(estimatedGas.toString())
      //   // if amount + fee > balance, call function recursively with skipSimulation = true and amount = amount - fee
      //   if (parseFloat(amount) + estimatedFee > ethBalance) {
      //     const confirm = window.confirm(
      //       `The fee + amount for this transaction is bigger than your balance. ~${estimatedFee} ETH will be deducted from the amount you are sending. Do you want to continue?`,
      //     )
      //     if (!confirm) throw new Error('Transaction cancelled')
      //     value = parseUnits((parseFloat(amount) - estimatedFee).toFixed(18), 18)
      //   }
      // }
      const data = toUtf8Bytes(memo) // Convert memo to bytes
      const hexData = hexlify(data) // Convert bytes to hex

      const tx = await signer.sendTransaction({ from, to, value, data: hexData })
      return tx.hash
    } else {
      const tokenContract = (asset.split('-')[1] || '').toLowerCase()
      const contract = new Contract(
        tokenContract,
        ['function decimals() view returns (uint8)', 'function transfer(address,uint)'],
        signer,
      )
      const decimals = await contract.decimals()
      const n = parseUnits(amount, decimals)
      // estimate contract call gas
      const estimatedGas = await contract.transfer.estimateGas(to, n)
      const estimatedFee = gasPriceEth * parseFloat(estimatedGas.toString())
      if (estimatedFee > ethBalance) {
        throw new Error('Not enough ETH for transaction fee')
      }
      const tx = await contract.transfer(to, n)
      return tx.hash
    }
  }

  async transferKujira(signer, { to, amount, asset, memo }, skipSimulation = false) {
    const denom =
      asset === 'KUJI.KUJI'
        ? 'ukuji'
        : 'factory/kujira1qk00h5atutpsv900x202pxx42npjr9thg58dnqpa72f2p7m2luase444a7/uusk'

    const assetAmount = (parseFloat(amount) * 1e6).toFixed(0)
    const msgToSend = {
      fromAddress: this.address,
      toAddress: to,
      amount: [{ denom, amount: assetAmount.toString() }],
    }
    const msgs = [msg.bank.msgSend(msgToSend)]
    const gasPrice = await CLIENTS.KUJI.getGasPrice('ukuji') // we can add support for other denoms later
    const client = await SigningStargateClient.connectWithSigner(this.config.endpoint, signer, {
      registry,
      gasPrice,
      aminoTypes: aminoTypes('kujira'),
      accountParser,
    })

    if (!skipSimulation) {
      const gasAmount = await client.simulate(this.address, msgs, memo)
      const gasPriceDecimals = parseFloat(gasPrice.replace('ukuji', '')) / 1e6
      const ukujiFee =
        parseFloat((gasAmount * this.kujiGasAdjustment * gasPriceDecimals).toFixed(6)) * 1e6
      const kujiBalance = (await CLIENTS.KUJI.balance(this.address)).find(
        (b) => b.asset === 'KUJI.KUJI',
      )?.balance
      // if amount + fee > balance, call function recursively with skipSimulation = true and amount = amount - fee
      const kujiFee = parseFloat(ukujiFee / 1e6)
      const totalKuji = asset === 'KUJI.KUJI' ? parseFloat(amount) + kujiFee : kujiFee
      if (totalKuji > kujiBalance) {
        // show a confirm dialog, explaining that the fee will be deducted from the amount.
        const safeUkujiFee = ukujiFee * 1.05
        const confirm = window.confirm(
          `The fee + amount for this transaction is bigger than your balance. ~${(safeUkujiFee / 1e6).toFixed(6)} KUJI will be deducted from the amount you are sending. Do you want to continue?`,
        )
        if (!confirm) throw new Error('Transaction cancelled')
        // add 5% to the fee to make sure it's enough, sometimes the simulation returns a lower fee than the actual fee
        const newAmount = ((parseFloat(assetAmount) - safeUkujiFee).toFixed(0) / 1e6).toFixed(6)
        return await this.transferKujira(signer, { to, amount: newAmount, asset, memo }, true)
      }
    }

    return (await client.signAndBroadcast(this.address, msgs, 1.5, memo)).transactionHash
  }

  async calculateCacaoFee(amount) {
    const mimir = 'https://node.eldorado.market/mayanode/mayachain/mimir'
    const fetchMimirResult = await fetch(mimir)
    const mimirJson = await fetchMimirResult.json()
    const nativeTxFee = parseFloat(mimirJson.NATIVETRANSACTIONFEE) / 1e10
    const balance = await CLIENTS.MAYA.balance(this.address)
    if (parseFloat(balance) < nativeTxFee) {
      throw new Error(`The minimum amount for a transaction is ${nativeTxFee / 1e10} CACAO`)
    }
    const amountPlusFee = parseFloat(amount) + nativeTxFee
    if (amountPlusFee > balance) {
      const confirm = window.confirm(
        `The fee + amount for this transaction is bigger than your balance. ~${nativeTxFee.toFixed(10)} CACAO will be deducted from the amount you are sending. Do you want to continue?`,
      )
      if (!confirm) throw new Error('Transaction cancelled')
      const newAmount = (parseFloat(amount) - nativeTxFee / 1e10).toFixed(10)
      return newAmount
    } else {
      return amount
    }
  }

  async calculateRuneFee(amount) {
    const networkUrl = 'https://thornode.ninerealms.com/thorchain/network'
    const network = await (await fetch(networkUrl)).json()
    const nativeFee = parseFloat(network.native_outbound_fee_rune) / 1e8
    if (parseFloat(amount) < nativeFee) {
      throw new Error(`The minimum amount for a transaction is ${nativeFee / 1e8} RUNE`)
    }
    const balance = await CLIENTS.THOR.balance(this.address)
    const amountPlusFee = parseFloat(amount) + nativeFee
    if (amountPlusFee > balance) {
      const confirm = window.confirm(
        `The fee + amount for this transaction is bigger than your balance. ~${nativeFee.toFixed(8)} RUNE will be deducted from the amount you are sending. Do you want to continue?`,
      )
      if (!confirm) throw new Error('Transaction cancelled')
      const newAmount = (parseFloat(amount) - nativeFee / 1e8).toFixed(8)
      return newAmount
    }
  }
}
