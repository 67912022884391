import { AlchemyProvider, HDNodeWallet, JsonRpcProvider, Wallet, toUtf8Bytes } from 'ethers'
import * as ecc from 'tiny-secp256k1'
import { BIP32Factory } from 'bip32'
import stringify from 'json-stable-stringify'
import createHash from 'create-hash'
import { getSeed } from '@xchainjs/xchain-crypto'
import { bech32 } from 'bech32'
import * as btc from '@scure/btc-signer'
import DashPhrase from 'dashphrase'
import DashHd from 'dashhd'
import DashKeys from 'dashkeys'
import DashTx from 'dashtx'
import { DirectSecp256k1HdWallet } from '@cosmjs/proto-signing'
import { BigNumber } from 'bignumber.js'
import { captureException } from '@sentry/browser'
import { BaseWallet } from './base'
import { INSIGHT_BASE_URL } from '~/clients/dash'
import { CHAINS_NATIVE_ASSET, CLIENTS } from '~/clients'

const ETHEREUM_DEV_NETWORK: string = 'mainnet' // 'goerli' for testnet, 'mainnet' for mainnet
const USE_ETH_NETWORK = process.env.NODE_ENV === 'development' && ETHEREUM_DEV_NETWORK === 'goerli'
const ETH_NETWORK = USE_ETH_NETWORK ? 'goerli' : 'mainnet'
// const ETHERSCAN_KEY = '2K6ZF6CMS5YJDYYZ18BB2EYRIT7V2JAPJY'
// const ETHERSCAN_URL =
//   ETH_NETWORK === 'mainnet' ? 'https://api.etherscan.io/api' : 'https://api-goerli.etherscan.io/api'
// const ETHERSCAN_GAS_ORACLE_URL = `${ETHERSCAN_URL}?module=gastracker&action=gasoracle&apikey=${ETHERSCAN_KEY}`

const ethRpcUrl = `https://eth-${ETH_NETWORK}.g.alchemy.com/v2/y8uEgC1cDd_N_8tIhZJDRPYFDnMUaywQ`
const arbRpcKey = 'BU8TokIdf3qW_vUV2DJZsJVTv3YQcOb3'
const mayanodeEndpoint = 'https://node.eldorado.market/mayanode'
// const kujiCosmosDirectory = 'https://chains.cosmos.directory/kujira'
// //@ts-ignore
const dashTx = DashTx.create({})

const bip32 = BIP32Factory(ecc)

export const TOKENS_DECIMALS = {
  '0xdac17f958d2ee523a2206206994597c13d831ec7': 6,
  '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48': 6,
  '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0': 18,
}

export class KeystoreWallet extends BaseWallet {
  declare signer: HDNodeWallet
  declare provider: JsonRpcProvider
  mnemonic: string = ''

  constructor(chain: string, mnemonic: string) {
    super(chain)
    this.mnemonic = mnemonic
    if (chain === 'ETH') {
      this.provider = new JsonRpcProvider(ethRpcUrl, ETH_NETWORK)
      this.signer = Wallet.fromPhrase(mnemonic, this.provider)
    }
    if (chain === 'ARB') {
      this.provider = new AlchemyProvider('arbitrum', arbRpcKey)
      this.signer = Wallet.fromPhrase(mnemonic, this.provider)
    }
    this.ethGasAdjustment = 2
    this.arbGasAdjustment = 2
    this.btcFeeAdjustment = 1.1
  }

  async initialize() {
    this.address = await this.getAddress()
  }

  async getAddress() {
    if (this.chain === 'THOR' || this.chain === 'MAYA' || this.chain === 'KUJI') {
      this.key = this.key || this.getKey(this.mnemonic)
      return bech32.encode(this.config.prefix, bech32.toWords(this.key.identifier))
    }
    if (this.chain === 'ETH' || this.chain === 'ARB') {
      return this.signer.address
    }
    if (this.chain === 'BTC') {
      this.key = this.key || this.getKey(this.mnemonic)
      return btc.getAddress('wpkh', this.key.privateKey)
    }
    if (this.chain === 'DASH') {
      const salt = ''
      const seedBytes = await DashPhrase.toSeed(this.mnemonic, salt)
      const walletKey = await DashHd.fromSeed(seedBytes)
      const accountIndex = 0
      const usage = 0
      const keyIndex = 0 // security note: reusing hard-coded key index leaks data
      const firstKeyPath = `m/44'/5'/${accountIndex}'/${usage}/${keyIndex}`
      const addressKey = await DashHd.derivePath(walletKey, firstKeyPath)
      const primaryAddress = await DashHd.toAddr(addressKey.publicKey)

      return primaryAddress
    }
  }

  getKey(mnemonic) {
    const kp = bip32
      .fromSeed(getSeed(mnemonic))
      .derivePath(this.chain === 'BTC' ? `84'/0'/0'/0/0` : `44'/${this.config.networkId}'/0'/0/0`)
    return {
      identifier: kp.identifier,
      publicKey: kp.publicKey,
      privateKey: kp.privateKey,
      keyPair: kp,
    }
  }

  async deposit({ amount, memo, asset }) {
    const inbounds = await (await fetch(mayanodeEndpoint + '/mayachain/inbound_addresses')).json()
    // let a = ''
    switch (this.chain) {
      case 'MAYA':
        return this.depositMayachain({ amount, memo, asset })
      case 'THOR':
        return this.depositThorchain({ inbounds, amount, memo, asset })
      case 'ETH':
        return this.depositEthereum({ inbounds, amount, memo, asset })
      case 'ARB':
        return this.depositArbitrum({ inbounds, amount, memo, asset })
      case 'BTC':
        return this.depositBitcoin({ inbounds, amount, memo, asset })
      case 'DASH':
        return this.depositDash({ inbounds, amount, memo, asset })
      case 'KUJI':
        return this.depositKujira({ inbounds, amount, memo, asset })
    }
  }

  async checkTokenAllowanceMaya(asset) {
    const inbounds = await (await fetch(mayanodeEndpoint + '/mayachain/inbound_addresses')).json()
    switch (this.chain) {
      case 'ETH':
        return this.checkEthereumTokenAllowanceMaya({ inbounds, asset })
      case 'ARB':
        return this.checkArbitrumTokenAllowanceMaya({ inbounds, asset })
    }
  }

  async checkTokenApproveFeeMaya(asset) {
    const inbounds = await (await fetch(mayanodeEndpoint + '/mayachain/inbound_addresses')).json()
    switch (this.chain) {
      case 'ETH':
        return this.checkEthereumTokenApproveFeeMaya({ inbounds, asset })
      case 'ARB':
        return this.checkArbitrumTokenApproveFeeMaya({ inbounds, asset })
    }
  }

  async approveOrCheckTokenRango({
    checkOrApprove,
    poolIn,
    poolOut,
    toAddress,
    amountIn,
  }: ApproveOrCheckTokenRangoParams) {
    switch (this.chain) {
      case 'ETH':
        return await this.checkEthereumApproveOrCheckTokenRango({
          checkOrApprove,
          poolIn,
          poolOut,
          toAddress,
          amountIn,
        })
      case 'ARB':
        return await this.checkArbitrumApproveOrCheckTokenRango({
          checkOrApprove,
          poolIn,
          poolOut,
          toAddress,
          amountIn,
        })
    }
  }

  async approveTokenMaya(asset) {
    const inbounds = await (await fetch(mayanodeEndpoint + '/mayachain/inbound_addresses')).json()
    switch (this.chain) {
      case 'ETH':
        return this.approveEthereumTokenMaya({ inbounds, asset })
      case 'ARB':
        return this.approveArbitrumTokenMaya({ inbounds, asset })
    }
  }

  async depositMayachain({ amount, memo, asset }, skipSimulation = false) {
    const assetName = asset || CHAINS_NATIVE_ASSET[this.chain]
    const amountStr = (parseFloat(amount) * (assetName === 'MAYA.CACAO' ? 1e10 : 1e8)).toFixed(0)
    if (assetName === 'MAYA.CACAO' && !skipSimulation) {
      const newAmount = await this.calculateCacaoFee(amount)
      if (newAmount > parseFloat(amount)) {
        return await this.depositMayachain({ amount: newAmount, memo, asset }, true)
      }
    }
    return await this.signAndBroadcastMessages([
      {
        type: 'mayachain/MsgDeposit',
        value: {
          coins: [{ asset: assetName, amount: amountStr }],
          memo,
          signer: this.address,
        },
      },
    ])
  }

  async depositRuneOnThorchain({ amount, memo, asset }) {
    const assetName = asset || CHAINS_NATIVE_ASSET[this.chain]

    const amountStr = new BigNumber(amount).times(1e8).toFixed(0)
    return await this.signAndBroadcastMessages([
      {
        type: 'thorchain/MsgDeposit',
        value: {
          coins: [{ asset: assetName, amount: amountStr }],
          memo,
          signer: this.address,
        },
      },
    ])
  }

  async depositThorchain({ inbounds, amount, memo, asset }, skipSimulation = false) {
    const inbound = inbounds.find((i) => i.chain === this.chain)
    if (!skipSimulation) {
      const newAmount = await this.calculateRuneFee(amount)
      if (newAmount > parseFloat(amount)) {
        return await this.depositThorchain({ inbounds, amount: newAmount, memo, asset }, true)
      }
    }
    return this.transferCosmos({ to: inbound.address, amount, memo, asset })
  }

  depositKujira({ inbounds, amount, memo, asset }) {
    const inbound = inbounds.find((i) => i.chain === this.chain)
    return this.transferKujira({ to: inbound.address, amount, memo, asset })
  }

  async signAndBroadcastMessages(messages, memo = '') {
    const result = await fetch(this.config.endpoint + '/auth/accounts/' + this.address).then((r) =>
      r.json(),
    )
    const account = result.result.value
    const tx = {
      msgs: messages,
      memo,
      chain_id: this.config.chainId,
      sequence: account.sequence || '0',
      account_number: account.account_number,
      fee: { gas: '10000000', amount: [] },
    }
    const hash = createHash('sha256').update(stringify(tx)).digest()
    const signature = this.key.keyPair.sign(hash)
    const typedArrayToBase64 = (a) => btoa(String.fromCharCode.apply(null, a))
    const txData = {
      msg: messages,
      memo,
      fee: { amount: [], gas: '10000000' },
      signatures: [
        {
          pub_key: {
            type: 'tendermint/PubKeySecp256k1',
            value: typedArrayToBase64(this.key.publicKey),
          },
          signature: typedArrayToBase64(signature),
          sequence: account.sequence || '0',
          account_number: account.account_number,
        },
      ],
    }

    const res = await fetch(this.config.endpoint + '/txs', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        mode: 'block',
        tx: txData,
      }),
    })
    const status = res.statusCode === null ? '' : res.statusCode
    const body = await res.json()
    if (!res.ok && body.error !== 'timed out waiting for tx to be included in a block') {
      throw new Error(`Error got non 2xx response code ${status}: ${body.error}`)
    }
    if (!body.logs && body.error !== 'timed out waiting for tx to be included in a block') {
      throw new Error('Error submiting transaction: ' + (body.error || body.raw_log))
    }
    if (!body.txhash) return ''
    return body.txhash
  }

  depositEthereum({ inbounds, amount, memo, asset = '' }) {
    return super.depositEthereum(this.signer, { inbounds, amount, memo, asset })
  }

  depositArbitrum({ inbounds, amount, memo, asset = '' }) {
    return super.depositArbitrum(this.signer, { inbounds, amount, memo, asset })
  }

  async depositBitcoin({ inbounds, amount, memo }) {
    const inbound = inbounds.find((i) => i.chain === 'BTC')
    const txHash = await this.transferBitcoin({ to: inbound.address, amount, memo })
    return txHash
  }

  async depositDash({ inbounds, amount, memo }) {
    const inbound = inbounds.find((i) => i.chain === 'DASH')
    const txHash = await this.transferDash({ to: inbound.address, amount, memo })
    return txHash
  }

  checkEthereumTokenAllowanceMaya({ inbounds, asset = '' }) {
    return super.checkEthereumTokenAllowanceMaya(this.signer, { inbounds, asset })
  }

  checkArbitrumTokenAllowanceMaya({ inbounds, asset = '' }) {
    return super.checkArbitrumTokenAllowanceMaya(this.signer, { inbounds, asset })
  }

  checkEthereumTokenApproveFeeMaya({ inbounds, asset = '' }) {
    return super.checkEthereumTokenApproveFeeMaya(this.signer, { inbounds, asset })
  }

  checkArbitrumTokenApproveFeeMaya({ inbounds, asset = '' }) {
    return super.checkArbitrumTokenApproveFeeMaya(this.signer, { inbounds, asset })
  }

  approveEthereumTokenMaya({ inbounds, asset = '' }) {
    return super.approveEthereumTokenMaya(this.signer, { inbounds, asset })
  }

  approveArbitrumTokenMaya({ inbounds, asset = '' }) {
    return super.approveArbitrumTokenMaya(this.signer, { inbounds, asset })
  }

  async checkEthereumApproveOrCheckTokenRango({
    checkOrApprove,
    poolIn,
    poolOut,
    toAddress,
    amountIn,
  }: ApproveOrCheckTokenRangoParams) {
    return await super.checkEthereumApproveOrCheckTokenRango({
      checkOrApprove,
      poolIn,
      poolOut,
      toAddress,
      amountIn,
      signer: this.signer,
      chain: this.chain,
    })
  }

  async checkArbitrumApproveOrCheckTokenRango({
    checkOrApprove,
    poolIn,
    poolOut,
    toAddress,
    amountIn,
  }: ApproveOrCheckTokenRangoParams) {
    return await super.checkArbitrumApproveOrCheckTokenRango({
      checkOrApprove,
      poolIn,
      poolOut,
      toAddress,
      amountIn,
      signer: this.signer,
      chain: this.chain,
    })
  }

  transfer({ to, amount, asset, memo }) {
    switch (this.chain) {
      case 'MAYA':
        return this.transferCosmos({ to, amount, asset, memo })
      case 'THOR':
        return this.transferCosmos({ to, amount, asset, memo })
      case 'ETH':
        return this.transferEthereum({ to, amount, asset })
      case 'ARB':
        return this.transferArbitrum({ to, amount, asset, memo })
      case 'BTC':
        return this.transferBitcoin({ to, amount, asset })
      case 'DASH':
        return this.transferDash({ to, amount, asset })
      case 'KUJI':
        return this.transferKujira({ to, amount, asset, memo })
    }
  }

  async transferCosmos({ to, amount, asset, memo = '' }) {
    let assetName = (asset || CHAINS_NATIVE_ASSET[this.chain]).toLowerCase()
    if (assetName.startsWith('thor.') || assetName.startsWith('maya.')) {
      assetName = assetName.slice(5)
    }
    let decimals = 1e8
    if (assetName === 'maya') decimals = 1e4
    if (assetName === 'cacao') {
      decimals = 1e10
      const newAmount = await this.calculateCacaoFee(amount)
      if (newAmount > amount) {
        return await this.transferCosmos({ to, amount: newAmount, asset }, true)
      }
    }
    const amountStr = (parseFloat(amount) * decimals).toFixed(0)
    const message = {
      type: `${this.chain === 'MAYA' ? 'mayachain' : 'thorchain'}/MsgSend`,
      value: {
        amount: [
          {
            denom: assetName,
            amount: amountStr,
          },
        ],
        from_address: this.address,
        to_address: to,
      },
    }
    return await this.signAndBroadcastMessages([message], memo)
  }

  transferEthereum({ to, amount, asset }) {
    const signer = this.signer
    return super.transferEthereum(signer, { to, amount, asset }, false)
  }

  transferArbitrum({ to, amount, asset, memo = '' }) {
    const signer = this.signer
    return super.transferArbitrum(signer, { to, amount, asset, memo }, false)
  }

  async transferBitcoin({ to, amount, memo = '' }, skipSimulation = false) {
    async function fetchUserUtxos(address) {
      const url = `https://mempool.space/api/address/${address}/utxo`
      return (await fetch(url)).json()
    }
    async function fetchFeeRate() {
      const res = await fetch(`https://mempool.space/api/v1/fees/recommended`)
      const data = await res.json()
      return data.fastestFee
    }
    function calculateFee(vins, vouts, feeRate) {
      const txSize = 10 + vins * 180 + vouts * 34
      return txSize * feeRate
    }

    if (memo && memo.length > 80) {
      throw new Error('memo too long, must not be longer than 80 chars.')
    }
    this.key = this.key || this.getKey(this.mnemonic)
    const tx = new btc.Transaction({ allowUnknowOutput: true })
    const memoBytes = toUtf8Bytes(memo)
    const utxos = await fetchUserUtxos(this.address)
    const feeRate = await fetchFeeRate()
    utxos.sort((a, b) => b.value - a.value)
    amount = parseInt(parseFloat(amount) * 1e8)
    let value = 0
    let fee = 0
    while (value < amount + fee) {
      const utxo = utxos.pop()

      // if (!utxo) throw new Error('Not enough funds in wallet for transaction')
      if (utxo) {
        tx.addInput({
          txid: utxo.txid,
          index: utxo.vout,
          witnessUtxo: {
            amount: BigInt(utxo.value),
            script: btc.p2wpkh(this.key.publicKey).script,
          },
        })
        value += utxo.value
        fee = calculateFee(tx.inputsLength, memo ? 3 : 2, feeRate)
      } else {
        break
      }
    }

    // check if the fee + amount is bigger than the balance
    if (!skipSimulation) {
      const balance = await CLIENTS.BTC.balance(this.address)
      const safeFee = parseFloat((parseFloat(fee * this.btcFeeAdjustment) / 1e8).toFixed(8))
      const amountPlusFee = parseFloat((parseFloat(amount) / 1e8 + safeFee).toFixed(8))
      if (amountPlusFee > balance) {
        const confirm = window.confirm(
          `The fee + amount for this transaction is bigger than your balance. ~${safeFee.toFixed(8)} BTC will be deducted from the amount you are sending. Do you want to continue?`,
        )
        const newAmount = amount - parseInt(safeFee * 1e8)
        if (confirm) {
          amount = parseInt(newAmount)
          fee = parseInt(safeFee * 1e8)
        } else {
          throw new Error('Transaction cancelled')
        }
      }
    }

    tx.addOutputAddress(to, BigInt(amount))
    if (memo) {
      tx.addOutput({
        amount: BigInt(0),
        script: new Uint8Array([106, 76, memoBytes.length, ...memoBytes]),
      })
    }
    const change = value - amount - fee
    if (change > 0) {
      tx.addOutputAddress(this.address, BigInt(change))
    }
    tx.sign(this.key.privateKey)
    tx.finalize()

    const res = await fetch(`https://mempool.space/api/tx`, {
      method: 'post',
      body: tx.hex,
    })
    if (res.status !== 200) {
      throw new Error(
        `Error submitting Bitcoin transaction: API returned ${res.status} ${res.statusText}\n\n${await res.text()}`,
      )
    }
    return await res.text()
  }

  /**
   * @param {String} address - a normal Base58Check-encoded PubKeyHash
   * @param {Number} amount - Dash, in decimal form (not sats)
   * @param {String} memo - the maya command string
   */
  async transferDash({ to, amount, memo }, skipSimulation = false) {
    const pubKeyHashBytes = await DashKeys.addrToPkh(to)
    const pubKeyHash = DashKeys.utils.bytesToHex(pubKeyHashBytes)
    const satoshis = DashTx.toSats(amount)
    const memoHex = DashTx.utils.strToHex(memo)

    // get the private key
    const salt = ''
    const seedBytes = await DashPhrase.toSeed(this.mnemonic, salt)
    const walletKey = await DashHd.fromSeed(seedBytes)
    const accountIndex = 0
    const usage = 0
    const keyIndex = 0 // security note: reusing hard-coded key index leaks data
    const firstKeyPath = `m/44'/5'/${accountIndex}'/${usage}/${keyIndex}`
    const addressKey = await DashHd.derivePath(walletKey, firstKeyPath)
    const primaryAddress = await DashHd.toAddr(addressKey.publicKey)
    const primaryPkhBytes = await DashKeys.addrToPkh(primaryAddress)
    const primaryPkh = DashKeys.utils.bytesToHex(primaryPkhBytes)

    // check the address balance
    const urlUTXO = `${INSIGHT_BASE_URL}/addr/${primaryAddress}/utxo`

    let resp = await fetch(urlUTXO)
    let text = await resp.text()
    let data
    try {
      data = JSON.parse(text)
    } catch (e) {
      captureException(`error: could parse ${urlUTXO}:\n${text}`)
      throw e
    }
    if (!resp.ok) {
      throw new Error(`bad response: ${text}`)
    }
    const insightUtxos = data

    // convert from Insight form to standard form
    const coins = insightUtxos.map((insightUtxo) => ({
      txId: insightUtxo.txid,
      outputIndex: insightUtxo.vout,
      address: insightUtxo.address,
      script: insightUtxo.scriptPubKey,
      satoshis: insightUtxo.satoshis,
    }))

    // setup outputs
    /** @type {Array<DashTx.TxOutput>} */
    const outputs = []
    const recipient = {
      pubKeyHash,
      satoshis,
    }
    outputs.push(recipient)
    if (memo) {
      outputs.push({ memo: memoHex, satoshis: 0 })
    }
    // create the transaction
    const changeOutput = { pubKeyHash: primaryPkh, satoshis: 0 }

    if (!skipSimulation) {
      const txAppraisal = await DashTx.appraise({ inputs: coins, outputs })
      const balance = await CLIENTS.DASH.balance(primaryAddress)
      const safeFee = parseFloat(txAppraisal.max) * 1.05
      const amountPlusFee = parseFloat((parseFloat(amount) + safeFee / 1e8).toFixed(8))
      if (amountPlusFee > balance) {
        const confirm = window.confirm(
          `The fee + amount for this transaction is bigger than your balance. ~${(safeFee / 1e8).toFixed(8)} DASH will be deducted from the amount you are sending. Do you want to continue?`,
        )
        if (!confirm) throw new Error('Transaction cancelled')
        const newAmount = (parseFloat(amount) - safeFee / 1e8).toFixed(8)
        return await this.transferDash({ to, amount: newAmount, memo }, true)
      }
    }

    const txInfo = await DashTx.createLegacyTx(coins, outputs, changeOutput)
    // sign the transaction
    const signOpts = {
      getPrivateKey: function () {
        return addressKey.privateKey
      },
    }
    const txInfoSigned = await dashTx.hashAndSignAll(txInfo, signOpts)
    const txHex = txInfoSigned.transaction.toString()
    const url = `${INSIGHT_BASE_URL}/tx/sendix`
    const payload = { rawtx: txHex }
    // doesn't allow newlines
    const body = JSON.stringify(payload)
    const req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    }

    resp = await fetch(url, req)
    text = await resp.text()
    try {
      data = JSON.parse(text)
    } catch (e) {
      captureException(`error: could parse ${url}:\n${text}`)
      throw e
    }
    if (!resp.ok) {
      throw new Error(`bad response: ${text}`)
    }
    const ret = data.txid

    return ret
  }

  async transferKujira({ to, amount, asset, memo = '' }) {
    const signer = await DirectSecp256k1HdWallet.fromMnemonic(this.mnemonic, { prefix: 'kujira' })
    return super.transferKujira(signer, { to, amount, asset, memo })
  }
}
